<template>
    <div class="main">
        <div class="header">
            <div class="money">
                <span class="currency">Rp</span>{{ amount }}
            </div>
            <p class="desc">OrderlD:{{ orderId }}</p>
        </div>
        <div class="content">
            <p class="payUsing">Bank Transfer</p>
            <div class="bank-content">
                <div class="bank-detail">
                    <p class="t1">Menunggu Pembayaran Anda</p>
                    <div class="icon" v-if="bank">
                        <img :src=bank.img />
                    </div>
                    <p class="sub_t1">Nomor Rekening Virtual</p>
                    <div class="num_copy">
                        {{ virAccountNo }}
                        <span @click="copyNum">Salin</span>
                    </div>
                    <div class="bank_name">
                        <!-- <p>Jumlah</p> -->
                        <p class="rp">Rp{{ amount }}</p>
                    </div>
                    <div class="status_pam">
                        <button>Status pembayaran</button>
                    </div>
                </div>
                <div class="tab-warp">
                    <div class="tab-top">
                        <div class="tab-item" :class="{ 'active': tabIndex == 0 }" @click="changeTabIndex(0)">
                            ATM
                        </div>
                        <div class="tab-item" :class="{ 'active': tabIndex == 1 }" @click="changeTabIndex(1)">
                            M-Banking
                        </div>
                        <div class="tab-item" :class="{ 'active': tabIndex == 2 }" @click="changeTabIndex(2)">
                            Internet Banking
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="tab-content-item" v-if="tabIndex == 0">
                            <div class="content-title">LANGKAH 1: CARI ATM TERDEKAT</div>
                            <div class="content-sub">
                                <p>1. Masukkan kartu, pilih bahasa dan masukkan PIN Anda</p>
                                <p>2. Pilih "Menu Lainnya" dan pilih "Pembayaran"</p>
                                <p>3. Pilih "Pembayaran Lainnya" dan pilih "Briva"</p>
                            </div>
                            <div class="content-title">LANGKAH 2: DETAIL PEMBAYARAN</div>
                            <div class="content-sub">
                                <p>1. Masukkan nomor rekening virtual <span class="txt-blue">{{ virAccountNo }}</span>,
                                    dan nominal yang ingin Anda bayar</p>
                                <p>2. Periksa data transaksi dan tekan "YA"</p>
                            </div>
                            <div class="content-title">LANGKAH 3: TRANSAKSI SELESAI</div>
                            <div class="content-sub">
                                <p>1. Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis.
                                    Ini bisa memakan waktu hingga 5 menit</p>
                            </div>
                        </div>
                        <div class="tab-content-item" v-if="tabIndex == 1">
                            <div class="content-title">LANGKAH 1: MASUK KE AKUN ANDA</div>
                            <div class="content-sub">
                                <p>1. Masuk ke Mobile Banking BRI, masukkan USER ID dan PIN Anda</p>
                                <p>2. Pilih "Pembayaran" dan pilih "Briva"</p>
                            </div>
                            <div class="content-title">LANGKAH 2: DETAIL PEMBAYARAN</div>
                            <div class="content-sub">
                                <p>1. Masukkan Nomor Rekening Virtual Anda <span
                                        class="txt-blue">{{ virAccountNo }}</span>, dan jumlah yang ingin Anda bayar</p>
                                <p>2. Masukkan PIN Anda dan klik "Kirim"</p>
                            </div>
                            <div class="content-title">LANGKAH 3: TRANSAKSI SELESAI</div>
                            <div class="content-sub">
                                <p>1. Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis.
                                    Ini bisa memakan waktu hingga 5 menit</p>
                            </div>
                        </div>
                        <div class="tab-content-item" v-if="tabIndex == 2">
                            <div class="content-title">LANGKAH 1: MASUK KE AKUN ANDA</div>
                            <div class="content-sub">
                                <p>1. Masuk ke Internet Banking BRI, masukkan USER ID dan Password Anda</p>
                                <p>2. Pilih "Pembayaran" dan pilih "Briva"</p>
                            </div>
                            <div class="content-title">LANGKAH 2: DETAIL PEMBAYARAN</div>
                            <div class="content-sub">
                                <p>1. Masukkan Nomor Rekening Virtual Anda <span
                                        class="txt-blue">{{ virAccountNo }}</span>, dan jumlah yang ingin Anda bayar,
                                    dan klik kirim</p>
                                <p>2. Masukkan password Anda lagi beserta kode otentikasi dari mToken internet banking
                                </p>
                            </div>
                            <div class="content-title">LANGKAH 3: TRANSAKSI SELESAI</div>
                            <div class="content-sub">
                                <p>1. Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis.
                                    Ini bisa memakan waktu hingga 5 menit</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">


            <hr />

            <!-- <div class="paragraph">
                <p>Tips:</p>
                <p>lf your payment is successful and your account has not increased the amountplease prowide your order
                    number
                    and
                    payment voucher to contact us assoon as possilble.</p>
            </div> -->
            <div class="mail">
                <p>
                    
                    Powered by pay.aio-pay.com ver:{{ $config.software_version }}</p>
            </div>
        </div>
        <OrderInfo :orderId=orderId></OrderInfo>
    </div>
</template>
<script>

import * as _cookie from '@/utils/cookie'
import OrderInfo from "./components/OrderInfo.vue";

export default {
    components: {
        OrderInfo
    },
    data() {
        return {
            amount: "",
            orderId: '',
            tabIndex: 0,
            virAccountNo: "",
            bank: {}
        }
    },
    created() {
        let data = _cookie.getData();

        this.amount = data.amt;
        this.orderId = data.orderId;
        this.virAccountNo = this.$tools.trimQuotes(data.virAccountNo)
        this.bank = data.bank

    },
    methods: {
        copyNum() {
            //拷贝数字
            // 创建一个临时的文本区域
            const textArea = document.createElement('textarea');
            textArea.value = this.virAccountNo;

            // 将文本区域添加到页面中
            document.body.appendChild(textArea);

            // 选中并复制文本
            textArea.select();
            document.execCommand('copy');

            // 移除临时文本区域
            document.body.removeChild(textArea);

            alert('Replicating Success!');
        },
        changeTabIndex(index) {
            this.tabIndex = index;
        }
    }

}
</script>
<style>
html,
body {
    padding: 0 !important;
    margin: 0 !important;
    background-color: #eee;
}
</style>
<style scoped>
* {
    font-family: '微软雅黑';

}

.main {
    min-height: 100vh;
}

.header {
    /* Safari 5.1 - 6.0 */
    background: -webkit-linear-gradient(to right, #367af7, #76a6ff);
    /* Opera 11.1 - 12.0 */
    background: -o-linear-gradient(to right, #367af7, #76a6ff);
    /* Firefox 3.6 - 15 */
    background: -moz-linear-gradient(to right, #367af7, #76a6ff);
    background: linear-gradient(to right, #367af7, #76a6ff);
    background-color: #367af7;
    height: 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.header .content {
    margin-top: .4rem;
    padding: 0 .4rem;
}

.header .money {
    color: #fff;
    font-size: 102px;
    padding-left: .4rem;
    font-family: '黑体';
    font-weight: 600;
    margin-top: .6rem;
}

.header .money .currency {
    font-size: 62px;
}

.header .desc {
    font-size: 24px;
    color: #d9e4ff;
    padding-left: 0.4rem;
    margin: 0.8rem 0 0 0;
}

.content {
    margin-top: .4rem;
    padding: 0 .4rem;
}


.content>p.payUsing {
    padding: 0;
    margin: 0;
    font-size: .6rem;
    text-align: left;
    color: #939393;
}

.bank-content {
    width: 100%;
    margin: 0.4rem auto 0 auto;
    background: #fff;
    border-radius: 0.186667rem;
    padding: 0.4rem 0.4rem;
    box-sizing: border-box;
}

.bank-detail {
    background: #FAFAFA;
    padding: .4rem .6rem;
    box-sizing: border-box;
}

.bank-detail p {
    margin: 0;
}

.bank-detail p.t1 {
    background: #FFF5E3;
    color: #b49d6e;
    font-size: .34rem;
    padding: .2rem 0;
}

.bank-detail>.icon {
    width: calc(100% - .8rem);
    background: #fff;
    margin: .4rem auto 0 auto;
    border-radius: 0.186667rem;
    padding: .4rem;
    box-sizing: border-box;
}

.bank-detail>.icon>img {
    width: 100%;
}

.bank-detail .sub_t1 {
    color: #858C91;
    font-size: .32rem;
    padding: .2rem 0;
    margin-top: .2rem;
}

.bank-detail .num_copy {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.4rem;
    font-weight: bold;
    text-align: center;
}

.bank-detail .num_copy>span {
    padding: .1rem .2rem;
    border-radius: 0.186667rem;
    background: #74BAFF;
    color: #fff;
    margin-left: 12px;
}


.bank-detail .bank_name {
    text-align: center;
    font-size: .4rem;
    margin-top: .8rem;


}

.bank-detail .bank_name p.rp {
    font-weight: bold;
}

.bank-detail .status_pam button {
    outline: none;
    border: none;
    padding: 0 .4rem;
    height: .9rem;
    line-height: .9rem;
    border-radius: 0.186667rem;
    background: #74BAFF;
    color: #fff;
}

.tab-warp {
    width: 100%;

}

.tab-top {
    width: 100%;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    flex-direction: row;

}

.tab-item {
    font-size: 0.36rem;
    width: 33.3333%;
    white-space: nowrap;
    padding: .38rem 0;
}

.tab-item.active {
    color: #75BAFF;
    border-bottom: 6px solid #75BAFF;
    margin-bottom: -3px;
}

.tab-content {
    width: 100%;
}

.tab-content .tab-content-item {
    width: 100%;
    padding: 0.4rem;
    box-sizing: border-box;
}


.tab-content .tab-content-item .content-title {
    font-size: .4rem;
    font-weight: bold;
    white-space: nowrap;
    text-align: left;
}

.tab-content .tab-content-item .content-sub {
    font-size: .34rem;
    text-align: left;
    color: #9b9b9b;
}

.tab-content .tab-content-item .content-sub p {
    margin-top: .28rem;
    margin-bottom: 0;
}

.tab-content .tab-content-item .content-sub .txt-blue {
    color: #75BAFF;
}





/* -----------------------footer------------------------ */



.footer {
    width: 100%;
    font-size: .4rem;
    margin-top: 2rem;
    padding-bottom: 200px;
}

.footer .footLogo {
    text-align: center;
}

.footer .footLogo p {
    margin: 0;
    color: #5d5d5d;
    font-size: .2rem;

}

.footer .footLogo img {
    width: 1.0rem;
    height: auto;
    margin-top: .1rem;
}

.footer .make {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translatex(-50%);
    width: 100%;
    background-color: #eee;
}

.footer .make>button {
    /* Safari 5.1 - 6.0 */
    background: -webkit-linear-gradient(to right, #367af7, #76a6ff);
    /* Opera 11.1 - 12.0 */
    background: -o-linear-gradient(to right, #367af7, #76a6ff);
    /* Firefox 3.6 - 15 */
    background: -moz-linear-gradient(to right, #367af7, #76a6ff);
    background: linear-gradient(to right, #367af7, #76a6ff);
    background-color: #367af7;
    color: #fff;
    font-size: .6rem;
    border: none;
    border-radius: .6rem;
    display: block;
    width: 90%;
    height: 1.4rem;
    margin: .6rem auto;
}

.footer .paragraph {
    text-align: left;
    padding: 0 .6rem;
    font-size: .2rem;
    color: #939393;
}

.footer .mail {
    text-align: left;
    padding: 0 0.6rem;
    font-size: .2rem;
}

.footer hr {
    margin: .2rem .6rem;
    border: 1px solid #fff;
}

.footer .envelope::after {
    font-family: fontAwesome;
    content: "\f0e0";
    font-weight: normal;
    font-style: normal;
    font-size: .4rem;
}
</style>